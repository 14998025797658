import {type SyntheticEvent, useCallback, useState} from 'react';

import {type AutocompleteInputChangeReason} from '@mui/material/Autocomplete';
import {debounce} from '@mui/material/utils';
import {
  type Listing,
  type ApiNodeListingGetRequest,
} from '@onroadvantage/onroadvantage-api';

import {nodeApi} from '../../../api';
import {catchHandler} from '../helpers/catchHandler';
import {type LoadStatus} from '../types/LoadStatus';

export type UseLoadNodeListingQueryOptions = Pick<
  ApiNodeListingGetRequest,
  'customerId' | 'nodetype'
> & {enableDateRangeFilter?: boolean};

export const useLoadNodeListing = (
  type: string,
  queryOptions: UseLoadNodeListingQueryOptions = {},
) => {
  const [initialListing, setInitialListing] = useState<Listing[] | null>(null);
  const [listing, setListing] = useState<Listing[]>([]);
  const [loadListingStatus, setLoadNodeListingStatus] =
    useState<LoadStatus>('idle');

  const loadListing = useCallback(
    async (query?: string, reason?: AutocompleteInputChangeReason) => {
      if (
        (reason === 'reset' || reason === 'clear') &&
        initialListing != null
      ) {
        setListing(initialListing);
        return;
      }
      setLoadNodeListingStatus('loading');
      try {
        const response = await nodeApi.apiNodeListingGet({
          ...queryOptions,
          query,
          type: type.toUpperCase(),
        });
        const mappedListing: Listing[] =
          response.items
            ?.filter((item) => item.name != null)
            .map((item) => ({
              value: item.id,
              metaData: JSON.stringify(item),
              label: `${item.name} - ${item.code}`,
            })) ?? [];
        if (initialListing == null) {
          setInitialListing(mappedListing);
        }
        setListing(mappedListing);
        setLoadNodeListingStatus('success');
      } catch (error) {
        const {type: errorType} = catchHandler(
          error,
          `Failed to load listing: ${type}`,
        );

        setLoadNodeListingStatus(errorType);
      }
    },
    [initialListing, type, queryOptions],
  );

  return {
    listing,
    loadListing,
    loadListingStatus,
    onDebounceSearch: debounce(
      async (
        _event: SyntheticEvent,
        value: string,
        reason: AutocompleteInputChangeReason,
      ) => {
        await loadListing(value, reason);
      },
      500,
    ),
  };
};
