import {toast} from 'react-toastify';

import {type WebMasterTripOptimised} from '@onroadvantage/onroadvantage-api';

import {tripApi} from '../../../api';
import {useDelete} from '../../common/hooks/useDelete';
import {type DeleteParams} from '../../common/types/DeleteParams';

export const useDeleteTrip = ({removeItem}: DeleteParams) => {
  return useDelete<WebMasterTripOptimised>(async ({id}) => {
    if (id == null) {
      toast('Trip ID not found', {type: 'warning'});
      return;
    }
    await tripApi.apiTripMasterTripIdDelete({
      masterTripId: id,
    });
    removeItem(id);
  });
};
