import {type InferType, object, date, string, boolean} from 'yup';

import {
  multipleListing,
  listing,
} from '../../common/components/form/validationSchemas';
import {VantageAutocompleteFieldWithListing} from '../../common/components/form/VantageAutocompleteFieldWithListing';
import {VantageBooleanField} from '../../common/components/form/VantageBooleanField';
import {VantageForm} from '../../common/components/form/VantageForm';
import {VantageFormActions} from '../../common/components/form/VantageFormActions';
import {VantageFormContent} from '../../common/components/form/VantageFormContent';
import {VantageFormFieldsRow} from '../../common/components/form/VantageFormFieldsRow';
import {VantageFormSubmit} from '../../common/components/form/VantageFormSubmit';
import {VantageTextField} from '../../common/components/form/VantageTextField';
import {VantageTimePickerField} from '../../common/components/form/VantageTimePickerField';
import {type FormProps} from '../../common/types/FormProps';

const validTimeMinutes = {
  match: /^\d{1,3}$/,
  message: 'Please enter a time in minutes',
};

const siteDetailsFormSchema = object({
  name: string().required('Required'),
  externalReference: string().required('Required'),
  address: string(),
  prohibitAfterHoursProcessing: boolean(),
  latitude: string()
    .required('Required')
    .matches(
      /^([+-])?(?:90(?:\.0{1,20})?|(?:[0-9]|[1-8][0-9])(?:\.[0-9]{1,20})?)$/,
      'This is should be a decimal number between -90 and 90',
    ),
  longitude: string()
    .required('Required')
    .matches(
      /^([+-])?(?:180(?:\.0{1,20})?|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:\.[0-9]{1,20})?)$/,
      'This is should be a decimal number between -180 and 180',
    ),
  openTime: date().required('Required'),
  closeTime: date().required('Required'),
  loadingTimeInMinutes: string()
    .required('Required')
    .matches(validTimeMinutes.match, validTimeMinutes.message),
  unloadingTimeInMinutes: string()
    .required('Required')
    .matches(validTimeMinutes.match, validTimeMinutes.message),
  processingTimeInMinutes: string()
    .required('Required')
    .matches(validTimeMinutes.match, validTimeMinutes.message),
  nodeType: listing.nullable().required('Required'),
  planningSkills: multipleListing,
});

export type SiteDetailsFormValues = InferType<typeof siteDetailsFormSchema>;

export function SiteDetailsForm(props: FormProps<SiteDetailsFormValues>) {
  return (
    <VantageForm {...props} validationSchema={siteDetailsFormSchema}>
      <VantageFormContent>
        <VantageFormFieldsRow>
          <VantageTextField
            name="name"
            placeholder="Enter the site name"
            label="Name"
            formik
          />
          <VantageTextField
            name="externalReference"
            placeholder="Enter the external reference"
            label="External Reference"
            formik
          />
        </VantageFormFieldsRow>
        <VantageFormFieldsRow>
          <VantageTextField
            name="latitude"
            placeholder="Enter the site latitude coordinate"
            label="Latitude"
            formik
          />
          <VantageTextField
            name="longitude"
            placeholder="Enter the site longitude coordinate"
            label="Longitude"
            formik
          />
        </VantageFormFieldsRow>
        <VantageTextField
          name="address"
          placeholder="e.g. 1 Newtown Drive, Newlands, Cape Town"
          label="Physical Address"
          InputLabelProps={{shrink: true}}
          formik
        />
        <VantageAutocompleteFieldWithListing
          name="nodeType"
          models="NodeType"
          label="Site Type"
          placeholder="Enter and select the site type"
          formik
        />
        <VantageFormFieldsRow>
          <VantageTimePickerField name="openTime" label="Open Time" formik />
          <VantageTimePickerField name="closeTime" label="Close Time" formik />
        </VantageFormFieldsRow>
        <VantageFormFieldsRow>
          <VantageTextField
            name="loadingTimeInMinutes"
            placeholder="e.g. 30"
            label="Loading Time"
            formik
          />
          <VantageTextField
            name="unloadingTimeInMinutes"
            placeholder="e.g. 30"
            label="Unloading Time"
            formik
          />
          <VantageTextField
            name="processingTimeInMinutes"
            placeholder="e.g. 15"
            label="Processing Time"
            formik
          />
        </VantageFormFieldsRow>
        <VantageAutocompleteFieldWithListing
          name="planningSkills"
          placeholder="Enter and select the site skills"
          label="Planning Skills"
          models="PlanningSkill"
          multiple
          formik
        />
        <VantageBooleanField
          name="prohibitAfterHoursProcessing"
          label="Prohibit After Hours Processing"
        />
      </VantageFormContent>
      <VantageFormActions>
        <VantageFormSubmit />
      </VantageFormActions>
    </VantageForm>
  );
}
