import {type ElementType, useEffect} from 'react';

import type {ChipTypeMap} from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import {
  VantageAutocompleteField,
  type VantageAutocompleteFieldProps,
} from './VantageAutocompleteField';
import {
  useLoadNodeListing,
  type UseLoadNodeListingQueryOptions,
} from '../../hooks/useLoadNodeListing';

type VantageAutocompleteFieldWithNodeListingProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = Omit<
  VantageAutocompleteFieldProps<
    Multiple,
    DisableClearable,
    FreeSolo,
    ChipComponent
  >,
  'options' | 'onInputChange' | 'inputValue' | 'loading' | 'loadingText'
> & {
  type: string;
  queryOptions?: UseLoadNodeListingQueryOptions;
};

export function VantageAutocompleteFieldWithNodeListing<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>({
  type,
  queryOptions,
  disabled,
  ...props
}: VantageAutocompleteFieldWithNodeListingProps<
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent
>) {
  const {listing, loadListing, loadListingStatus, onDebounceSearch} =
    useLoadNodeListing(type, queryOptions);

  useEffect(() => {
    if (disabled !== true) {
      void loadListing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  return (
    // @ts-expect-error TODO Fix Formik Boolean type error
    <VantageAutocompleteField
      options={listing}
      onInputChange={onDebounceSearch}
      disabled={disabled}
      limitTags={10}
      {...props}
      loading={loadListingStatus === 'loading'}
      loadingText={
        <Typography color="text.secondary">
          Loading <strong>{type}</strong> listing...
        </Typography>
      }
    />
  );
}
