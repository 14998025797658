import {type Node} from '@onroadvantage/onroadvantage-api';

import {nodeApi} from '../../../api';
import {useSubmitForm} from '../../common/hooks/useSubmitForm';
import {type EditParams} from '../../common/types/EditParams';
import {type SiteDetailsFormValues} from '../components/SiteDetailsForm';

export const useEditSiteDetails = ({
  id: nodeId,
  updateState,
}: EditParams<Node>) => {
  return useSubmitForm<SiteDetailsFormValues>(async (values) => {
    const filteredPlanningSkillIds = values.planningSkills
      ?.filter(
        ({value}) => value !== undefined && value !== null && value !== 0,
      )
      .map(({value}) => value);
    const openTime = values.openTime.toTimeString();
    const closeTime = values.closeTime.toTimeString();
    const response = await nodeApi.apiNodeNodeIdPatch({
      nodeId,
      body: {
        name: values.name,
        externalReference: values.externalReference,
        prohibitAfterHoursProcessing: values.prohibitAfterHoursProcessing,
        address: values.address,
        latitude: parseFloat(values.latitude),
        longitude: parseFloat(values.longitude),
        timeWindows: [
          {
            openTime,
            closeTime,
          },
        ],
        loadingTimeInMinutes: parseFloat(values.loadingTimeInMinutes),
        unloadingTimeInMinutes: parseFloat(values.unloadingTimeInMinutes),
        processingTimeInMinutes: parseFloat(values.processingTimeInMinutes),
        nodeTypeId: values.nodeType.value,
        planningSkillIds: filteredPlanningSkillIds,
      },
    });
    updateState(response);
  });
};
