import {useEffect, useRef} from 'react';
import {MapContainer, Marker, TileLayer, GeoJSON} from 'react-leaflet';

import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import {type Node} from '@onroadvantage/onroadvantage-api';
import {type GeoJsonObject} from 'geojson';
import {type Map} from 'leaflet';

import {config} from '../../../config';
import {getNestedBorderRadius} from '../../../theme/getNestedBorderRadius';
import 'leaflet/dist/leaflet.css';
import '../../common/components/map/map.css';
import {InfoIcon} from '../../common/components/map/vantageMapIcons';

export function SiteMap({node}: {node: Node}) {
  const mapRef = useRef<Map>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef?.current;
    const resizeObserver = new ResizeObserver(() => {
      if (mapRef.current != null) {
        mapRef.current.invalidateSize({animate: true});
      }
    });

    if (container != null) {
      resizeObserver.observe(container);
    }

    return () => {
      if (container != null) {
        resizeObserver.unobserve(container);
      }
    };
  }, [containerRef]);

  return (
    <StyledMapContainer ref={containerRef}>
      <MapContainer
        zoomControl={false}
        doubleClickZoom={false}
        touchZoom={false}
        markerZoomAnimation
        className="vantage-map"
        ref={mapRef}
        center={[node.latitude, node.longitude]}
        zoom={19}
      >
        <TileLayer
          url={config.mapResources.streetsTilingUrl}
          tileSize={256}
          maxZoom={19}
          updateWhenIdle
        />
        {node.geofencesGeoJson != null && (
          <GeoJSON data={node.geofencesGeoJson as GeoJsonObject} />
        )}
        <Marker icon={InfoIcon} position={[node.latitude, node.longitude]} />
      </MapContainer>
    </StyledMapContainer>
  );
}

export const StyledMapContainer = styled(Stack)(({theme}) => ({
  borderRadius: getNestedBorderRadius(theme, 1),
  position: 'relative',
  overflow: 'hidden',
  flex: 1,
}));
