import {type SyntheticEvent, useCallback, useState} from 'react';
import {toast, type TypeOptions} from 'react-toastify';

import {type AutocompleteInputChangeReason} from '@mui/material/Autocomplete';
import {debounce} from '@mui/material/utils';
import {type Listing} from '@onroadvantage/onroadvantage-api';

import {orderApi} from '../../../api';
import {isObjectWithKeys} from '../../common/helpers/unknownValueTypeChecks';
import {useDateRangeStore} from '../../common/stores/dateRangeStore';
import type {LoadStatus} from '../../common/types/LoadStatus';

export const useLoadUnassignedOrdersListing = () => {
  const startDate = useDateRangeStore((state) => state.startDate);
  const endDate = useDateRangeStore((state) => state.endDate);
  const [initialListing, setInitialListing] = useState<Listing[] | null>(null);
  const [listing, setListing] = useState<Listing[]>([]);
  const [loadOrderListingStatus, setLoadOrderListingStatus] =
    useState<LoadStatus>('idle');

  const loadOrderListing = useCallback(
    async (query?: string, reason?: AutocompleteInputChangeReason) => {
      if (
        (reason === 'reset' || reason === 'clear') &&
        initialListing != null
      ) {
        setListing(initialListing);
        return;
      }
      setLoadOrderListingStatus('loading');
      try {
        const response = await orderApi.apiOrderGet({
          page: 1,
          perPage: 25,
          getUnassignedOrdersOnly: true,
          orderBy: 'orderNumber',
          orderNumber: query,
          startDate: new Date(startDate),
          endDate: new Date(endDate),
        });
        const mappedListing: Listing[] =
          response.items?.map((item) => ({
            label: item.orderNumber,
            value: item.id,
            metaData: JSON.stringify(item),
          })) ?? [];

        if (initialListing == null) {
          setInitialListing(mappedListing);
        }
        setListing(mappedListing);
        setLoadOrderListingStatus('success');
      } catch (error) {
        let errorMessage = `Failed to load orders`;
        let type: TypeOptions = 'error';

        if (
          isObjectWithKeys(error, 'message') &&
          typeof error.message === 'string'
        ) {
          errorMessage = error.message;
        }

        if (isObjectWithKeys(error, 'cause') && error.cause === 'warning') {
          type = 'warning';
        }

        const toastId = `${errorMessage}|${type}`;

        if (!toast.isActive(toastId)) {
          toast(errorMessage, {type, toastId});
        }

        setLoadOrderListingStatus(type === 'error' ? 'error' : 'warning');
      }
    },
    [endDate, initialListing, startDate],
  );

  return {
    listing,
    loadOrderListing,
    loadOrderListingStatus,
    onDebounceSearch: debounce(
      async (
        _event: SyntheticEvent,
        value: string,
        reason: AutocompleteInputChangeReason,
      ) => {
        await loadOrderListing(value, reason);
      },
      500,
    ),
  };
};
