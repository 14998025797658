import {
  AdminTelematicsConfigApi,
  AuthorizationApi,
  BookingApi,
  BookingMasterTripApi,
  BroadcastApi,
  Configuration,
  ContactApi,
  ContactGroupApi,
  ContractApi,
  ContractBroadcastTemplateApi,
  ContractCriticalEventTypeConfigApi,
  ContractGroupApi,
  ContractMediaEventDriverReasonCodeApi,
  ContractOperationalEventTypeConfigApi,
  ContractTaskTemplateApi,
  CriticalEventApi,
  CriticalEventEscalationConfigApi,
  CriticalEventTypeApi,
  CustomerApi,
  CustomerNodeApi,
  DashboardApi,
  DocumentStorageApi,
  DocumentTemplateApi,
  DriverApi,
  DriverGroupApi,
  EventNotificationConfigApi,
  EscalationNotificationConfigApi,
  FixedRouteApi,
  ListingApi,
  MasterRouteApi,
  MasterTripApi,
  MasterTripCreditApi,
  MasterTripDebriefApi,
  MasterTripDebriefConfigApi,
  MasterTripListDriverApi,
  MasterTripListVehicleApi,
  MutedCriticalEventTypeApi,
  MutedOperationalEventTypeApi,
  NodeApi,
  NodeTypeApi,
  NotificationConfigApi,
  OpenaiAssistantPreviewApi,
  OperationalEventApi,
  OperationalEventEscalationConfigApi,
  OperationalEventTypeApi,
  OrderApi,
  OrderLineApi,
  PermissionApi,
  PlanningBoardApi,
  PlanningMasterTripApi,
  PlanningOrdersApi,
  PlanningSkillApi,
  PlanningSolutionApi,
  ProductApi,
  ProductGroupApi,
  ProfileApi,
  ProfileThemeApi,
  PurchaseOrderApi,
  ReasonCodeApi,
  RecipientApi,
  ReportApi,
  ReportScheduleApi,
  RoleApi,
  SpeedTableApi,
  TaskApi,
  TaskNotificationConfigApi,
  TaskTemplateApi,
  TelematicsEventApi,
  TransporterApi,
  TripApi,
  TripStopApi,
  TwilioApi,
  UploadApi,
  UserApi,
  VehicleApi,
  VehicleCapacityDimensionApi,
  VehicleCompartmentApi,
  VehicleCompartmentCapacityDimensionLayerApi,
  VehicleGroupApi,
  VehicleTypeApi,
  WebContractApi,
  WebDocumentStorageApi,
  WebEventApi,
  WebhookApi,
  WebhookAuthApi,
  WebMasterTripApi,
  WebMasterTripDebriefApi,
  WebMasterTripDebriefConfigApi,
  WebNodeApi,
  WebPlanningSkillCustomerApi,
  WebPlanningSkillDriverGroupApi,
  WebPlanningSkillNodeApi,
  WebPlanningSkillNodeTypeApi,
  WebPlanningSkillProductGroupApi,
  WebPlanningSkillVehicleGroupApi,
  WebPlanningSkillVehicleTypeApi,
  WebPlanningSolutionApi,
  WebSpeedTableApi,
  WorkflowApi,
  WorkflowConfigApi,
  IntegrationScheduleApi,
} from '@onroadvantage/onroadvantage-api';

import {config} from './config';
import {useAuthStore} from './modules/common/stores/authStore';

const getToken = () => {
  try {
    let {token, ssoToken} = useAuthStore.getState();
    useAuthStore.subscribe((authStore) => {
      token = authStore.token;
      ssoToken = authStore.ssoToken;
    });

    if (ssoToken != null) {
      return `Bearer ${ssoToken}`;
    }

    return token ?? '-';
  } catch (e) {
    return '-';
  }
};

export const getAuthHeaderValues = () => {
  let {token, ssoToken} = useAuthStore.getState();
  useAuthStore.subscribe((authStore) => {
    token = authStore.token;
    ssoToken = authStore.ssoToken;
  });

  if (ssoToken != null) {
    return {
      name: 'Authentication-Token',
      value: `Bearer ${ssoToken}`,
    };
  }

  return {
    name: 'Authentication-Token',
    value: token ?? '-',
  };
};

const preAuthConfiguration = new Configuration({
  basePath: config.serverUri,
  middleware: [
    {
      post: async ({response}) => {
        if (!response.ok) {
          throw new Error(await response.text());
        }
      },
    },
  ],
});

const configuration = new Configuration({
  basePath: () => config.serverUri,
  apiKey: getToken,
  middleware: [
    {
      post: async ({response}) => {
        if (!response.ok) {
          throw new Error(await response.text());
        }
      },
    },
  ],
});

export const adminTelematicsConfigApi = new AdminTelematicsConfigApi(
  configuration,
);
export const authorizationApi = new AuthorizationApi(preAuthConfiguration);
export const bookingApi = new BookingApi(configuration);
export const bookingMasterTripApi = new BookingMasterTripApi(configuration);
export const broadcastApi = new BroadcastApi(configuration);
export const contactApi = new ContactApi(configuration);
export const contactGroupApi = new ContactGroupApi(configuration);
export const contractApi = new ContractApi(configuration);
export const contractBroadcastTemplateApi = new ContractBroadcastTemplateApi(
  configuration,
);
export const contractCriticalEventTypeConfigApi =
  new ContractCriticalEventTypeConfigApi(configuration);
export const contractGroupApi = new ContractGroupApi(configuration);
export const contractMediaEventDriverReasonCodeApi =
  new ContractMediaEventDriverReasonCodeApi(configuration);
export const contractOperationalEventTypeConfigApi =
  new ContractOperationalEventTypeConfigApi(configuration);
export const contractTaskTemplateApi = new ContractTaskTemplateApi(
  configuration,
);
export const criticalEventApi = new CriticalEventApi(configuration);
export const criticalEventEscalationConfigApi =
  new CriticalEventEscalationConfigApi(configuration);
export const criticalEventTypeApi = new CriticalEventTypeApi(configuration);
export const customerApi = new CustomerApi(configuration);
export const customerNodeApi = new CustomerNodeApi(configuration);
export const dashboardApi = new DashboardApi(configuration);
export const documentStorageApi = new DocumentStorageApi(configuration);
export const documentTemplateApi = new DocumentTemplateApi(configuration);
export const driverApi = new DriverApi(configuration);
export const driverGroupApi = new DriverGroupApi(configuration);
export const escalationNotificationConfigApi =
  new EscalationNotificationConfigApi(configuration);
export const eventNotificationConfigApi = new EventNotificationConfigApi(
  configuration,
);
export const fixedRouteApi = new FixedRouteApi(configuration);
export const integrationScheduleApi = new IntegrationScheduleApi(configuration);
export const listingApi = new ListingApi(configuration);
export const masterRouteApi = new MasterRouteApi(configuration);
export const masterTripApi = new MasterTripApi(configuration);
export const masterTripCreditApi = new MasterTripCreditApi(configuration);
export const masterTripDebriefApi = new MasterTripDebriefApi(configuration);
export const masterTripDebriefConfigApi = new MasterTripDebriefConfigApi(
  configuration,
);
export const masterTripListDriverApi = new MasterTripListDriverApi(
  configuration,
);
export const masterTripListVehicleApi = new MasterTripListVehicleApi(
  configuration,
);
export const mutedCriticalEventTypeApi = new MutedCriticalEventTypeApi(
  configuration,
);
export const mutedOperationalEventTypeApi = new MutedOperationalEventTypeApi(
  configuration,
);
export const nodeApi = new NodeApi(configuration);
export const nodeTypeApi = new NodeTypeApi(configuration);
export const notificationConfigApi = new NotificationConfigApi(configuration);
export const openaiAssistantPreviewApi = new OpenaiAssistantPreviewApi(
  configuration,
);
export const operationalEventApi = new OperationalEventApi(configuration);
export const operationalEventEscalationConfigApi =
  new OperationalEventEscalationConfigApi(configuration);
export const operationalEventTypeApi = new OperationalEventTypeApi(
  configuration,
);
export const orderApi = new OrderApi(configuration);
export const orderLineApi = new OrderLineApi(configuration);
export const permissionApi = new PermissionApi(configuration);
export const planningBoardApi = new PlanningBoardApi(configuration);
export const planningMasterTripApi = new PlanningMasterTripApi(configuration);
export const planningOrderApi = new PlanningOrdersApi(configuration);
export const planningSkillApi = new PlanningSkillApi(configuration);
export const planningSolutionApi = new PlanningSolutionApi(configuration);
export const productApi = new ProductApi(configuration);
export const productGroupApi = new ProductGroupApi(configuration);
export const profileApi = new ProfileApi(configuration);
export const profileThemeApi = new ProfileThemeApi(configuration);
export const purchaseOrderApi = new PurchaseOrderApi(configuration);
export const reasonCodeApi = new ReasonCodeApi(configuration);
export const recipientApi = new RecipientApi(configuration);
export const reportApi = new ReportApi(configuration);
export const reportScheduleApi = new ReportScheduleApi(configuration);
export const roleApi = new RoleApi(configuration);
export const speedTableApi = new SpeedTableApi(configuration);
export const taskApi = new TaskApi(configuration);
export const taskNotificationConfigApi = new TaskNotificationConfigApi(
  configuration,
);
export const taskTemplateApi = new TaskTemplateApi(configuration);
export const telematicsEventApi = new TelematicsEventApi(configuration);
export const transporterApi = new TransporterApi(configuration);
export const tripApi = new TripApi(configuration);
export const tripStopApi = new TripStopApi(configuration);
export const twilioApi = new TwilioApi(configuration);
export const uploadApi = new UploadApi(configuration);
export const userApi = new UserApi(configuration);
export const vehicleApi = new VehicleApi(configuration);
export const vehicleCapacityDimensionApi = new VehicleCapacityDimensionApi(
  configuration,
);
export const vehicleCompartmentApi = new VehicleCompartmentApi(configuration);
export const vehicleCompartmentCapacityDimensionLayerApi =
  new VehicleCompartmentCapacityDimensionLayerApi(configuration);
export const vehicleGroupApi = new VehicleGroupApi(configuration);
export const vehicleTypeApi = new VehicleTypeApi(configuration);
export const webContractApi = new WebContractApi(configuration);
export const webDocumentStorageApi = new WebDocumentStorageApi(configuration);
export const webEventApi = new WebEventApi(configuration);
export const webhookApi = new WebhookApi(configuration);
export const webhookAuthApi = new WebhookAuthApi(configuration);
export const webMasterTripApi = new WebMasterTripApi(configuration);
export const webMasterTripDebriefApi = new WebMasterTripDebriefApi(
  configuration,
);
export const webMasterTripDebriefConfigApi = new WebMasterTripDebriefConfigApi(
  configuration,
);
export const webNodeApi = new WebNodeApi(configuration);
export const webPlanningSkillCustomerApi = new WebPlanningSkillCustomerApi(
  configuration,
);
export const webPlanningSkillDriverGroupApi =
  new WebPlanningSkillDriverGroupApi(configuration);
export const webPlanningSkillNodeApi = new WebPlanningSkillNodeApi(
  configuration,
);
export const webPlanningSkillNodeTypeApi = new WebPlanningSkillNodeTypeApi(
  configuration,
);
export const webPlanningSkillProductGroupApi =
  new WebPlanningSkillProductGroupApi(configuration);
export const webPlanningSkillVehicleGroupApi =
  new WebPlanningSkillVehicleGroupApi(configuration);
export const webPlanningSkillVehicleTypeApi =
  new WebPlanningSkillVehicleTypeApi(configuration);
export const webPlanningSolutionApi = new WebPlanningSolutionApi(configuration);
export const webSpeedTableApi = new WebSpeedTableApi(configuration);
export const workflowApi = new WorkflowApi(configuration);
export const workflowConfigApi = new WorkflowConfigApi(configuration);
