import {useNavigate} from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type {MasterRoute} from '@onroadvantage/onroadvantage-api';

import {routes} from '../../../routes';
import {OverlayLoader} from '../../common/components/loader/OverlayLoader';
import type {VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {VantageTablePaper} from '../../common/components/table/styledComponents/VantageTablePaper';
import {VantageTable} from '../../common/components/table/VantageTable';
import {getTooltipTableColumnValues} from '../../common/helpers/getTooltipTableColumnValues';
import {useFiltering} from '../../common/hooks/useFiltering';
import {usePagination} from '../../common/hooks/usePagination';
import {useSorting} from '../../common/hooks/useSorting';
import {useUserStore} from '../../common/stores/userStore';
import {useDeleteMasterRoute} from '../hooks/useDeleteMasterRoute';
import {useLoadMasterRoutes} from '../hooks/useLoadMasterRoutes';

const tableConfig: VantageTableConfig<MasterRoute> = {
  columns: [
    {
      name: 'name',
      label: 'Name',
      type: 'string',
      enableFilter: true,
      enableSort: true,
      enableGrouping: true,
    },
    {
      name: 'masterTripPrefix',
      label: 'Master Trip Prefix',
      type: 'string',
      enableFilter: true,
      enableSort: true,
      enableGrouping: true,
    },
    {
      name: 'contractCode',
      label: 'Contract Code',
      type: 'string',
      enableFilter: true,
      enableSort: true,
      getValue: ({contract}) => contract.code,
    },
    {
      name: 'fromSites',
      label: 'From Sites',
      type: 'string',
      getValue: ({legs}) =>
        getTooltipTableColumnValues(
          legs?.map((leg) => leg.fromNode?.name ?? '-'),
          1,
        ),
    },
    {
      name: 'toSites',
      label: 'To Sites',
      type: 'string',
      getValue: ({legs}) =>
        getTooltipTableColumnValues(
          legs?.map((leg) => leg.toNode?.name ?? '-'),
          1,
        ),
    },
  ],
  identifier: 'masterRoutes',
  enableColumnResizing: true,
  enableColumnGrouping: true,
  enableColumnVisibility: true,
  deleteMessage: (row) => (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Master Route Name:</Typography>
        <Typography>{row.name}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Master Trip Prefix:</Typography>
        <Typography>{row.masterTripPrefix}</Typography>
      </Stack>
    </Stack>
  ),
};

export default function MasterRoutes() {
  const hasPermissions = useUserStore((state) => state.hasPermissions);
  const navigate = useNavigate();
  const {
    setCurrentPage,
    tablePagination,
    loadRequestPagination,
    updatePagination,
  } = usePagination();
  const {tableSorting, loadRequestSorting} = useSorting({setCurrentPage});
  const {tableFiltering, getFilter} = useFiltering({setCurrentPage});
  const {data, loadDataStatus, reloadData, removeItem} = useLoadMasterRoutes({
    getFilter,
    loadRequestPagination,
    loadRequestSorting,
    updatePagination,
  });
  const {onDelete, deleteStatus} = useDeleteMasterRoute({removeItem});

  return (
    <VantageTablePaper>
      <OverlayLoader
        loading={loadDataStatus === 'idle'}
        label="Preparing content..."
      />
      <OverlayLoader
        loading={loadDataStatus === 'loading'}
        label="Loading master routes..."
      />
      <OverlayLoader
        loading={loadDataStatus === 'reloading'}
        label="Reloading master routes..."
      />
      <OverlayLoader
        loading={deleteStatus === 'deleting'}
        label="Deleting master route..."
      />
      <VantageTable
        config={tableConfig}
        data={data}
        rowId="id"
        toolbar={{onRefresh: reloadData}}
        actions={{
          onAdd: hasPermissions('masterRoute:add')
            ? () => {
                navigate(routes.masterRoute.add);
              }
            : undefined,
        }}
        rowActions={{
          onNavigate: ({id}) => {
            if (id != null) {
              navigate(
                routes.replaceParams(routes.masterRoute.masterRouteId, {
                  masterRouteId: id,
                }),
              );
            }
          },
          onDelete: hasPermissions('masterRoute:delete') ? onDelete : undefined,
        }}
        pagination={tablePagination}
        sorting={tableSorting}
        filtering={tableFiltering}
      />
    </VantageTablePaper>
  );
}
