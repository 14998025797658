import type {ReactNode} from 'react';

import AssessmentIcon from '@mui/icons-material/AssessmentRounded';
import AssignmentIcon from '@mui/icons-material/AssignmentRounded';
import BugReportRoundedIcon from '@mui/icons-material/BugReportRounded';
import DnsIcon from '@mui/icons-material/DnsRounded';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjectsRounded';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import NextWeekIcon from '@mui/icons-material/NextWeekRounded';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlayRounded';
import RssFeedIcon from '@mui/icons-material/RssFeedRounded';
import SettingsIcon from '@mui/icons-material/SettingsRounded';
import SupportIcon from '@mui/icons-material/SupportRounded';

import {analysisMenuItems} from './analysisMenuItems';
import {broadcastMenuItems} from './broadcastMenuItems';
import {debriefingMenuItems} from './debriefingMenuItems';
import {documentationMenuItems} from './documentationMenuItems';
import {executionMenuItems} from './executionMenuItems';
import {masterDataMenuItems} from './masterData';
import {planningMenuItems} from './planningMenuItems';
import {settingsMenuItems} from './settingsMenuItems';
import {vantageLabsMenuItems} from './vantageLabsMenuItems';
import {config} from '../../../../../config';
import {type Permission} from '../../../../../permissions';
import {routes} from '../../../../../routes';

export type MenuItemLevel = 1 | 2 | 3;

export interface MenuItem {
  label: string;
  level: MenuItemLevel;
  icon?: ReactNode;
  pathname?: string | null;
  permission?: Permission | null;
  children?: MenuItem[];
}

export const menuItems: MenuItem[] = [
  {
    level: 1,
    label: 'Planning',
    icon: <AssignmentIcon color="action" />,
    children: planningMenuItems,
  },
  {
    level: 1,
    label: 'Execution',
    icon: <PlaylistPlayIcon color="action" />,
    children: executionMenuItems,
  },
  {
    level: 1,
    label: 'Debriefing',
    icon: <NextWeekIcon color="action" />,
    children: debriefingMenuItems,
  },
  {
    level: 1,
    label: 'Analysis',
    icon: <AssessmentIcon color="action" />,
    children: analysisMenuItems,
  },
  {
    level: 1,
    label: 'Broadcasting',
    icon: <RssFeedIcon color="action" />,
    children: broadcastMenuItems,
  },
  {
    level: 1,
    label: 'Vantage Labs',
    icon: <EmojiObjectsIcon color="action" />,
    permission: 'vantageLabs:view',
    children: vantageLabsMenuItems,
  },
  {
    level: 1,
    label: 'Master Data',
    icon: <DnsIcon color="action" />,
    children: masterDataMenuItems,
  },
  {
    level: 1,
    label: 'Settings',
    icon: <SettingsIcon color="action" />,
    children: settingsMenuItems,
  },
  {
    level: 1,
    label: 'Documentation',
    icon: <FolderRoundedIcon color="action" />,
    children: documentationMenuItems,
  },
  ...(config.freshdeskSupportUrl != null &&
  config.freshdeskSupportUrl !== '' &&
  config.freshdeskSupportUrl !== '-'
    ? [
        {
          level: 1,
          label: 'Support',
          icon: <SupportIcon color="action" />,
          pathname: routes.support,
          permission: 'support:view',
        } satisfies MenuItem,
      ]
    : []),
  {
    level: 1,
    label: 'Report a bug',
    icon: <BugReportRoundedIcon color="action" />,
    pathname: routes.feedback,
  },
];
