import {type MenuItem} from './menuItems';
import {routes} from '../../../../../routes';

export const settingsMenuItems: MenuItem[] = [
  {
    level: 2,
    label: 'Permissions',
    pathname: routes.permission.list,
    permission: 'permission:list',
  },
  {
    level: 2,
    label: 'Profiles',
    pathname: routes.profile.list,
    permission: 'profile:list',
  },
  {
    level: 2,
    label: 'Roles',
    pathname: routes.role.list,
    permission: 'role:list',
  },
  {
    level: 2,
    label: 'Task Templates',
    pathname: routes.taskTemplate.list,
    permission: 'taskTemplate:list',
  },
  {
    level: 2,
    label: 'Telematics Configs',
    pathname: routes.telematicsConfig.list,
    permission: 'superadmin',
  },
  {
    level: 2,
    label: 'Users',
    pathname: routes.user.list,
    permission: 'user:list',
  },
];
