import {useMemo} from 'react';

import Stack from '@mui/material/Stack';
import {type Node} from '@onroadvantage/onroadvantage-api';

import {SiteDetailsForm, type SiteDetailsFormValues} from './SiteDetailsForm';
import {type EditProps} from '../../common/types/EditProps';
import {DateUtil} from '../../common/utils/DateUtil';
import {useEditSiteDetails} from '../hooks/useEditSiteDetails';

export function SiteDetailsContent({item, ...editParams}: EditProps<Node>) {
  const {onSubmit} = useEditSiteDetails(editParams);

  const initialValues = useMemo<SiteDetailsFormValues>(() => {
    return {
      name: item?.name ?? '',
      externalReference: item?.externalReference ?? '',
      prohibitAfterHoursProcessing: item?.prohibitAfterHoursProcessing,
      address: item?.address ?? '',
      latitude: item?.latitude.toString() ?? '',
      longitude: item?.longitude.toString() ?? '',
      openTime:
        DateUtil.fromServerTimeToLocalTimeDate(
          item?.timeWindows?.[0]?.openTime,
        ) ?? new Date(),
      closeTime:
        DateUtil.fromServerTimeToLocalTimeDate(
          item?.timeWindows?.[(item?.timeWindows?.length ?? 1) - 1]?.closeTime,
        ) ?? new Date(),
      loadingTimeInMinutes: item?.loadingTimeInMinutes?.toString() ?? '',
      unloadingTimeInMinutes: item?.unloadingTimeInMinutes?.toString() ?? '',
      processingTimeInMinutes: item?.processingTimeInMinutes?.toString() ?? '',
      nodeType: {
        value: item?.nodeType?.id ?? 0,
        label: item?.nodeType?.name ?? '',
      },
      planningSkills:
        item?.skills?.map(({id, name, description}) => ({
          value: id,
          label: `${name} - ${description}`,
        })) ?? [],
    };
  }, [item]);

  return (
    <Stack flex={1}>
      <SiteDetailsForm
        permission="site:edit"
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    </Stack>
  );
}
