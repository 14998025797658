import type {MasterRoute} from '@onroadvantage/onroadvantage-api';

import {masterRouteApi} from '../../../api';
import {useLoadList} from '../../common/hooks/useLoadList';
import type {UseLoadParams} from '../../common/types/UseLoadParams';

export const useLoadMasterRoutes = ({
  getFilter,
  updatePagination,
  loadRequestPagination,
  loadRequestSorting,
}: UseLoadParams) => {
  return useLoadList<MasterRoute>(
    async () =>
      masterRouteApi.apiMasterRouteGet({
        ...loadRequestPagination,
        ...loadRequestSorting,
        name: getFilter('name'),
        contractCode: getFilter('contractCode'),
        masterTripPrefix: getFilter('masterTripPrefix'),
      }),
    updatePagination,
    [getFilter, loadRequestSorting, loadRequestPagination],
  );
};
