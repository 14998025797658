import Stack from '@mui/material/Stack';
import {type Node} from '@onroadvantage/onroadvantage-api';

import {SiteDetailsContent} from './SiteDetailsContent';
import {SiteMap} from './SiteMap';
import {type EditProps} from '../../common/types/EditProps';

export function SiteDetail(props: EditProps<Node>) {
  const {id: nodeId, item: site, updateState: state} = props;
  return (
    <Stack direction="row">
      <SiteDetailsContent id={nodeId} updateState={state} item={site} />
      {site != null && <SiteMap node={site} />}
    </Stack>
  );
}
