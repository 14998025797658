import {DateTime} from 'luxon';

const fromServerTimeToLocalTimeOnly = (
  date: string | undefined | null,
): string | undefined => {
  if (date != null) {
    const separator = ':';

    const values = date.split(separator);
    const hour = parseInt(values[0], 10);
    const minute = parseInt(values[1], 10);

    const base = DateTime.utc().startOf('day').set({hour, minute}).toLocal();

    return `${base.hour}:${base.minute}:${base.second}`;
  }

  return undefined;
};

const fromServerTimeToLocalJSDate = (
  date: string | undefined | null,
): Date | undefined => {
  if (date != null) {
    const separator = ':';

    const values = date.split(separator);
    const hour = parseInt(values[0], 10);
    const minute = parseInt(values[1], 10);

    const base = DateTime.utc();

    return base.set({hour, minute}).toLocal().toJSDate();
  }

  return undefined;
};

const fromLocalDateToServerTime = (date: Date | string | null | undefined) => {
  if (date == null) {
    return null;
  }
  const dateTime =
    typeof date === 'string'
      ? DateTime.fromISO(date)
      : DateTime.fromJSDate(date);

  const dateTimeUTC = dateTime.toUTC();

  return `${dateTimeUTC.hour}:${dateTimeUTC.minute}:${dateTimeUTC.second}`;
};

const toTimeOnly = (date: Date | string | undefined) => {
  if (date != null) {
    const dateTime =
      typeof date === 'string'
        ? DateTime.fromISO(date)
        : DateTime.fromJSDate(date);

    return `${dateTime.hour}:${dateTime.minute}:${dateTime.second}`;
  }

  return undefined;
};

const dateFormats = {ShortDateTime: 'yyyy LLL dd HH:mm'} as const;

export type TFormatDate = (
  date: Date | string | undefined,
  format: keyof typeof dateFormats | string,
) => string | null | undefined;

const format: TFormatDate = (date, formatParam) => {
  if (date == null) {
    return null;
  }

  let dateTime: DateTime | null = null;

  if (typeof date === 'string') {
    dateTime = DateTime.fromISO(date);
  }

  if (date instanceof Date) {
    dateTime = DateTime.fromJSDate(date);
  }

  return dateTime?.toFormat(
    formatParam in dateFormats
      ? dateFormats[formatParam as keyof typeof dateFormats]
      : formatParam,
  );
};

const fromNow = (date: Date | undefined | null) => {
  if (date != null) {
    const duration = DateTime.fromJSDate(date)
      .diffNow()
      .shiftTo('months', 'days', 'hours', 'minutes');

    if (duration.months === -1) {
      return 'a month ago';
    }

    if (duration.months < -1) {
      return `${Math.floor(duration.months * -1)} months ago`;
    }

    if (duration.days < -2) {
      return `${Math.floor(duration.days * -1)} days ago`;
    }

    if (duration.hours === -1) {
      return 'an hour ago';
    }

    if (duration.hours < -1) {
      return `${Math.floor(duration.hours * -1)} hours ago`;
    }

    if (duration.minutes === -1) {
      return 'a minute ago';
    }

    if (duration.minutes < -1) {
      return `${Math.floor(duration.minutes * -1)} minutes ago`;
    }

    return undefined;
  }

  return undefined;
};

const toLocalDateTime = (date: Date | null | undefined): Date | undefined => {
  /**
   * Note: This is only to be used when the backend doesn't correctly save the datetime in utc format.
   * Because what happens is the frontend sends the utc datetime in the payload and when the backend resends that same
   * datetime value, the frontend doesn't know that it is in utc. So it will convert (the actual utc value) to utc again.
   */
  if (date != null) {
    const dateTime = DateTime.fromJSDate(date);
    const utcOffset = DateTime.fromJSDate(date).offset / 60;
    const localDateTime = dateTime.plus({hour: utcOffset});
    return localDateTime.toJSDate();
  }

  return undefined;
};

const timeDisplayFromMinutes = (minutes: number) => {
  const days = Math.floor(minutes / (24 * 60));
  const hours = Math.floor((minutes % (24 * 60)) / 60);
  const remainingMinutes = Math.round(minutes % 60);

  const result: string[] = [];

  if (days > 1) {
    result.push(`${days} days`);
  }

  if (hours > 1 || (hours === 1 && days === 0)) {
    result.push(`${hours}hr`);
  }

  if (remainingMinutes > 0 || result.length === 0) {
    result.push(`${remainingMinutes}min`);
  }

  return result.join(' ');
};

const timeDisplayFromStartAndEnd = (
  startDate: Date | undefined | null,
  endDate: Date | undefined | null,
) => {
  if (startDate == null || endDate == null) {
    return null;
  }

  // Get the time difference in milliseconds
  const timeDifference = endDate.getTime() - startDate.getTime();

  // Convert the time difference to minutes
  const differenceInMinutes = timeDifference / (1000 * 60);

  return timeDisplayFromMinutes(differenceInMinutes);
};

export const DateUtil = {
  toTimeOnly,
  fromServerTimeToLocalTimeOnly,
  fromServerTimeToLocalTimeDate: fromServerTimeToLocalJSDate,
  toLocalDateTime,
  fromLocalDateToServerTimeOnly: fromLocalDateToServerTime,
  format,
  fromNow,
  timeDisplayFromMinutes,
  timeDisplayFromStartAndEnd,
};
