import {
  type ComponentType,
  type CSSProperties,
  type DragEvent,
  memo,
  type MouseEvent,
  type MouseEventHandler,
  type ReactNode,
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';

import {
  CustomPaging,
  EditingState,
  type Filter,
  FilteringState,
  type Grouping,
  GroupingState,
  IntegratedGrouping,
  IntegratedSelection,
  PagingState,
  RowDetailState,
  SelectionState,
  type Sorting,
  SortingState,
  type TableRowDetail as TableRowDetailBase,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  DragDropProvider,
  Grid,
  PagingPanel,
  GroupingPanel,
  type Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableEditRow,
  TableFilterRow,
  TableFixedColumns,
  TableGroupRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';

import {TableToolbarCustomAction} from './components/TableToolbarCustomAction';
import {TableToolbarDaterangeFilterButton} from './components/TableToolbarDaterangeFilterButton';
import {TableToolbarInfo} from './components/TableToolbarInfo';
import {TableToolbarRefreshButton} from './components/TableToolbarRefreshButton';
import {VantageTableBase} from './components/VantageTableBase';
import {VantageTableRowDetail} from './components/VantageTableRowDetail';
import {useVantageTableActionsColumn} from './hooks/useVantageTableActionsColumn';
import {
  useVantageTableConfig,
  type VantageTableConfig,
} from './hooks/useVantageTableConfig';
import ActionsTypeProvider from './providers/ActionsTypeProvider';
import AutocompleteTypeProvider from './providers/AutocompleteProvider';
import BooleanTypeProvider from './providers/BooleanTypeProvider';
import DateTimeTypeProvider from './providers/DateTimeTypeProvider';
import DateTypeProvider from './providers/DateTypeProvider';
import LinkTypeProvider from './providers/LinkTypeProvider';
import NumberTypeProvider from './providers/NumberTypeProvider';
import ProgressTypeProvider from './providers/ProgressTypeProvider';
import StringTypeProvider from './providers/StringTypeProvider';
import {VantageTableEditCellComponent} from './styledComponents/VantageTableEditCellComponent';
import TableFilterRowCellComponent from './styledComponents/VantageTableFilterRowCellComponent';
import {VantageTableFixedCellComponent} from './styledComponents/VantageTableFixedCellComponent';
import {VantageTableGridRootComponent} from './styledComponents/VantageTableGridRootComponent';
import {VantageTableGroupingItemComponent} from './styledComponents/VantageTableGroupingItemComponent';
import {VantageTableGroupingPanelComponent} from './styledComponents/VantageTableGroupingPanelComponent';
import {VantageTableHeaderRowCellComponent} from './styledComponents/VantageTableHeaderRowCellComponent';
import {VantageTableHeaderRowComponent} from './styledComponents/VantageTableHeaderRowComponent';
import {VantageTablePagingPanelContainer} from './styledComponents/VantageTablePagingPanelContainer';
import {VantageTableSelectionRowComponent} from './styledComponents/VantageTableSelectionRowComponent';
import {VantageTableToolbarRootComponent} from './styledComponents/VantageTableToolbarRootComponent';
import {isObjectWithKeys} from '../../helpers/unknownValueTypeChecks';
import {useUserPreferencesStore} from '../../stores/userPreferencesStore';
import {PermissionsLoader} from '../loader/PermissionsLoader';

export type RowChangesType<T> = Record<string, T>;
export interface VantageTableFiltering {
  filters: Filter[];
  onFiltersChange: (filters: Filter[]) => void;
}
export interface VantageTableSorting {
  sorting: Sorting[];
  onSortingChange: (sorting: Sorting[]) => void;
}

interface VantageTableGrouping {
  grouping: Grouping[];
  onGroupingChange: (grouping: Grouping[]) => void;
}

interface VantageTableSelection<TableData = object> {
  selection: TableData[];
  onSelectionChange: (selection: TableData[]) => void;
  enableRowSelectable?: (row: TableData) => boolean;
}

export interface VantageTablePagination {
  currentPage: number;
  pageSize: number;
  itemTotal: number;
  pageSizeCounts?: number[];
  pageTotal: number;
  onPageSizeCountsChange: (value: number) => void;
  onCurrentPageChange: (value: number) => void;
}

export type VantageTableOnInlineAdd = (
  values: Record<string, unknown>,
) => Promise<'success' | 'catch'>;

export type VantageTableOnInlineEdit = (
  rowId: number | string | symbol,
  newValues: Record<string, unknown>,
) => Promise<'success' | 'catch'>;

export type VantageTableOnInlineEditAll<TableData = object> = (
  changes: Record<string, TableData>,
) => Promise<'success' | 'catch'>;

export interface VantageTableActions<TableData = object> {
  onAdd?: () => void;
  onDownload?: (data: TableData[]) => void;
  onInlineAdd?: VantageTableOnInlineAdd;
  onInlineEdit?: VantageTableOnInlineEditAll<TableData>;
  selection?: VantageTableSelection<TableData>; // All Selection
}

export interface VantageTableRowActions<TableData = object> {
  selection?: VantageTableSelection<TableData>;
  onAuxNavigate?: (row: TableData) => string | null | undefined;
  onNavigate?: (row: TableData) => void;
  onDelete?: (row: TableData) => void;
  onDownloadItem?: (row: TableData) => Promise<'success' | 'catch'>;
  onDragStart?: (
    event: DragEvent<HTMLTableRowElement> & {target: {id: unknown}},
    row: TableData,
  ) => void;
  onWarning?: (row: TableData) => {visible: boolean; message: string};
  onRightClick?: (
    event: MouseEvent<HTMLTableRowElement>,
    row: TableData,
  ) => void;
  onInlineEdit?: VantageTableOnInlineEdit;
}

interface VantageTableDateRange {
  isDateRangeFilterActive: boolean;
  onDateRangeFilterToggle: MouseEventHandler<HTMLButtonElement>;
}

export interface VantageTableToolbar {
  toolbarCustomAction?: ReactNode;
  toolbarInfo?: ReactNode;
  dateRangeFilter?: VantageTableDateRange;
  grouping?: VantageTableGrouping;
  onRefresh?: MouseEventHandler<HTMLButtonElement>;
}

export interface VantageTableProps<TableData extends object> {
  config: VantageTableConfig<TableData>;
  data: TableData[];
  rowId: keyof TableData;
  slotProps?: {
    gridRootProps?: {style?: CSSProperties};
    tableContainerProps?: {style?: CSSProperties};
  };
  grouping?: Grouping[];
  pagination?: VantageTablePagination;
  filtering?: VantageTableFiltering;
  sorting?: VantageTableSorting;
  rowDetail?: ComponentType<TableRowDetailBase.ContentProps>;
  actions?: VantageTableActions<TableData>;
  rowActions?: VantageTableRowActions<TableData>;
  // onEnableRowEdit?: (row: TableData) => boolean;
  rowComponent?: ComponentType<Table.DataRowProps>;
  toolbar?: VantageTableToolbar;
}

export function VantageTable<TableData extends object>({
  config,
  data,
  rowId,
  slotProps,
  grouping,
  pagination,
  filtering,
  sorting,
  rowComponent,
  rowDetail,
  actions,
  rowActions,
  toolbar,
}: VantageTableProps<TableData>) {
  const {
    columns,
    stringColumns,
    hiddenColumns: defaultHiddenColumns,
    autocompleteMulti,
    numberColumns,
    groupColumns,
    filterColumns,
    sortColumns,
    dateColumns,
    datetimeColumns,
    progressColumns,
    booleanColumns,
    linkColumns,
    columnExtensions,
    resizeDefaultWidths,
  } = useVantageTableConfig<TableData>(config);
  const tableHiddenColumns = useUserPreferencesStore(
    (state) => state.tableHiddenColumns,
  );
  const setTableHiddenColumns = useUserPreferencesStore(
    (state) => state.setTableHiddenColumns,
  );
  const tableGroupedColumns = useUserPreferencesStore(
    (state) => state.tableGroupedColumns,
  );
  const setTableGroupedColumns = useUserPreferencesStore(
    (state) => state.setTableGroupedColumns,
  );

  const [editingRowIds, setEditingRowIds] = useState<Array<number | string>>(
    [],
  );
  const [rowChanges, setRowChanges] = useState<RowChangesType<TableData>>();
  const [addedRows, setAddedRows] = useState<Array<Record<string, unknown>>>(
    [],
  );
  const actionsColumnWidth = useVantageTableActionsColumn(
    actions,
    rowActions,
    rowDetail,
  );

  const [expandedRowIds, setExpandedRowIds] = useState<Array<number | string>>(
    [],
  );

  const gridColumns = useMemo(
    () => [
      ...(rowDetail != null || rowActions != null || actions != null
        ? [{name: 'actions', title: 'Actions'}]
        : []),
      ...columns,
    ],
    [actions, columns, rowActions, rowDetail],
  );

  const hiddenColumns = useMemo(() => {
    if (config.enableColumnVisibility !== true || config.identifier == null) {
      return defaultHiddenColumns;
    }
    if (tableHiddenColumns?.[config.identifier] == null) {
      return defaultHiddenColumns;
    }
    return tableHiddenColumns[config.identifier];
  }, [config, defaultHiddenColumns, tableHiddenColumns]);

  const groupedColumns = useMemo(() => {
    if (config.enableColumnGrouping !== true || config.identifier == null) {
      return grouping ?? [];
    }
    if (tableGroupedColumns?.[config.identifier] == null) {
      return [];
    }
    return tableGroupedColumns[config.identifier].map((columnName) => ({
      columnName,
    }));
  }, [config, grouping, tableGroupedColumns]);

  const defaultColumnWidths = useMemo(
    () => [
      {columnName: 'actions', width: actionsColumnWidth},
      ...resizeDefaultWidths,
    ],
    [actionsColumnWidth, resizeDefaultWidths],
  );

  const baseColumnExtensions = useMemo(
    () => [
      {columnName: 'actions', width: actionsColumnWidth},
      ...(columnExtensions as Table.ColumnExtension[]),
    ],
    [actionsColumnWidth, columnExtensions],
  );

  const resizeColumnExtensions = useMemo(
    () => [
      {
        columnName: 'actions',
        maxWidth: actionsColumnWidth,
        minWidth: actionsColumnWidth,
      },
    ],
    [actionsColumnWidth],
  );

  const handleHiddenColumnNamesChange = (newHiddenColumns: string[]) => {
    if (config.enableColumnVisibility === true && config.identifier != null) {
      setTableHiddenColumns(config.identifier, newHiddenColumns);
    }
  };

  const handleGroupedColumnNamesChange = (newHiddenColumns: Grouping[]) => {
    if (config.enableColumnGrouping === true && config.identifier != null) {
      setTableGroupedColumns(
        config.identifier,
        newHiddenColumns.map(({columnName}) => columnName),
      );
    }
  };

  const enableFilter =
    filtering != null || (actions != null && Object.keys(actions).length > 0);

  const renderRootComponent = useCallback(
    (props: Grid.RootProps) => {
      return (
        <VantageTableGridRootComponent
          {...props}
          {...slotProps?.gridRootProps}
          $background={config.backgroundColor}
        />
      );
    },
    [config.backgroundColor, slotProps?.gridRootProps],
  );

  const renderFixedCellComponent = useCallback(
    (props: TableFixedColumns.CellProps) => {
      return (
        <VantageTableFixedCellComponent
          {...props}
          $background={config.backgroundColor}
        />
      );
    },
    [config.backgroundColor],
  );

  return (
    <Suspense fallback={<PermissionsLoader />}>
      <Grid
        columns={gridColumns}
        rows={data}
        getRowId={(row) => row[rowId] as string}
        rootComponent={renderRootComponent}
      >
        {stringColumns.length > 0 && (
          <StringTypeProvider
            for={stringColumns}
            config={config}
            rowId={rowId}
          />
        )}
        {autocompleteMulti.length > 0 && (
          <AutocompleteTypeProvider
            for={autocompleteMulti}
            config={config}
            rowId={rowId}
          />
        )}
        {numberColumns.length > 0 && (
          <NumberTypeProvider
            for={numberColumns}
            config={config}
            rowId={rowId}
          />
        )}
        {dateColumns.length > 0 && (
          <DateTypeProvider for={dateColumns} config={config} rowId={rowId} />
        )}
        {datetimeColumns.length > 0 && (
          <DateTimeTypeProvider
            for={datetimeColumns}
            config={config}
            rowId={rowId}
          />
        )}
        {progressColumns.length > 0 && (
          <ProgressTypeProvider for={progressColumns} />
        )}
        {booleanColumns.length > 0 && (
          <BooleanTypeProvider
            for={booleanColumns}
            config={config}
            rowId={rowId}
          />
        )}
        {linkColumns.length > 0 && <LinkTypeProvider for={linkColumns} />}
        {(rowDetail != null || rowActions != null || actions != null) && (
          <ActionsTypeProvider
            editingRowIds={editingRowIds}
            expandedRowIds={expandedRowIds}
            setExpandedRowIds={setExpandedRowIds}
            setEditingRowIds={setEditingRowIds}
            setAddedRows={setAddedRows}
            config={config}
            rowId={rowId}
            rowDetail={rowDetail}
            data={data}
            setRowChanges={setRowChanges}
            rowChanges={rowChanges}
            addedRows={addedRows}
            onInlineEdit={rowActions?.onInlineEdit}
            onInlineAdd={actions?.onInlineAdd}
            actions={actions ?? {}}
            rowActions={rowActions ?? {}}
          />
        )}
        {(actions?.onInlineAdd != null ||
          rowActions?.onInlineEdit != null ||
          actions?.onInlineEdit != null) && (
          <EditingState
            editingRowIds={editingRowIds}
            rowChanges={rowChanges}
            onRowChangesChange={setRowChanges}
            onAddedRowsChange={setAddedRows}
            onCommitChanges={() => {}}
            addedRows={addedRows}
          />
        )}
        {rowDetail != null && (
          <RowDetailState
            expandedRowIds={expandedRowIds}
            onExpandedRowIdsChange={setExpandedRowIds}
          />
        )}
        {enableFilter && (
          <FilteringState
            columnExtensions={filterColumns}
            defaultFilters={filtering?.filters}
            onFiltersChange={filtering?.onFiltersChange}
          />
        )}
        {sorting != null && (
          <SortingState
            columnExtensions={sortColumns}
            defaultSorting={sorting.sorting}
            onSortingChange={sorting.onSortingChange}
          />
        )}
        {rowActions?.selection != null && (
          <SelectionState
            selection={rowActions?.selection.selection.map((selected) => {
              if (
                isObjectWithKeys(selected, rowId) &&
                typeof selected[rowId] !== 'number'
              ) {
                return parseInt(String(selected[rowId]), 10);
              }
              if (isObjectWithKeys(selected, rowId)) {
                return selected[rowId] as number;
              }
              return 0;
            })}
          />
        )}
        {config.enableColumnGrouping === true && (
          <GroupingState
            grouping={groupedColumns}
            onGroupingChange={handleGroupedColumnNamesChange}
            columnExtensions={groupColumns}
          />
        )}
        {pagination != null && (
          <PagingState
            defaultCurrentPage={0}
            currentPage={(pagination.currentPage ?? 1) - 1}
            onCurrentPageChange={pagination.onCurrentPageChange}
            pageSize={pagination.pageSize}
            onPageSizeChange={pagination.onPageSizeCountsChange}
          />
        )}
        {config.enableColumnGrouping === true && <IntegratedGrouping />}
        {config.enableColumnGrouping === true && <DragDropProvider />}
        {rowActions?.selection != null && <IntegratedSelection />}
        <VantageTableBase
          backgroundColor={config.backgroundColor}
          fitHeight={config.fitHeight}
          rowComponent={rowComponent}
          config={config}
          slotProps={slotProps}
          onDragStart={rowActions?.onDragStart}
          onRightClick={rowActions?.onRightClick}
          columnExtensions={baseColumnExtensions}
        />
        {config.enableColumnResizing === true && (
          <TableColumnResizing
            defaultColumnWidths={defaultColumnWidths}
            resizingMode="nextColumn"
            columnExtensions={resizeColumnExtensions}
          />
        )}
        <TableHeaderRow
          contentComponent={VantageTableHeaderRowComponent}
          cellComponent={VantageTableHeaderRowCellComponent}
          showSortingControls={sorting != null}
        />
        {enableFilter && (
          <TableFilterRow cellComponent={TableFilterRowCellComponent} />
        )}
        {(actions?.onInlineAdd != null ||
          rowActions?.onInlineEdit != null ||
          actions?.onInlineEdit != null) && (
          <TableEditRow cellComponent={VantageTableEditCellComponent} />
        )}
        {rowDetail != null && <VantageTableRowDetail rowDetail={rowDetail} />}
        {rowActions?.selection != null &&
          config.selection?.selectionType === 'row' && (
            <TableSelection
              selectByRowClick
              highlightRow={false}
              showSelectionColumn={false}
              rowComponent={VantageTableSelectionRowComponent}
            />
          )}
        {pagination != null && (
          <PagingPanel
            containerComponent={VantageTablePagingPanelContainer}
            pageSizes={pagination.pageSizeCounts ?? [10, 25, 50, 100]}
          />
        )}
        {pagination != null && (
          <CustomPaging
            totalCount={
              pagination.itemTotal ??
              (pagination.pageSize ?? 25) * (pagination.pageTotal ?? 1)
            }
          />
        )}
        {config.enableColumnGrouping === true && (
          <TableGroupRow indentColumnWidth={0} />
        )}
        {(config.enableColumnVisibility === true ||
          hiddenColumns.length > 0) && (
          <TableColumnVisibility
            hiddenColumnNames={hiddenColumns}
            onHiddenColumnNamesChange={handleHiddenColumnNamesChange}
          />
        )}
        {(toolbar != null ||
          config.enableColumnVisibility === true ||
          config.enableColumnGrouping === true) && (
          <Toolbar rootComponent={VantageTableToolbarRootComponent} />
        )}
        {config.enableColumnGrouping === true && (
          <GroupingPanel
            showSortingControls={false}
            containerComponent={VantageTableGroupingPanelComponent}
            itemComponent={VantageTableGroupingItemComponent}
            showGroupingControls
          />
        )}
        {toolbar?.toolbarCustomAction != null && (
          <TableToolbarCustomAction
            toolbarCustomAction={toolbar?.toolbarCustomAction}
          />
        )}
        {config.enableDateRangeToggle === true && (
          <TableToolbarDaterangeFilterButton />
        )}
        {toolbar?.onRefresh != null && (
          <TableToolbarRefreshButton onRefresh={toolbar?.onRefresh} />
        )}
        {config.enableColumnVisibility === true && <ColumnChooser />}
        {toolbar?.toolbarInfo != null && (
          <TableToolbarInfo toolbarInfo={toolbar?.toolbarInfo} />
        )}
        {(Object.keys(actions ?? rowActions ?? {}).length > 0 ||
          rowDetail != null) && (
          <TableFixedColumns
            leftColumns={['actions']}
            cellComponent={renderFixedCellComponent}
          />
        )}
      </Grid>
    </Suspense>
  );
}

export const MemoizedVantageTable = memo(VantageTable) as typeof VantageTable;
